import React,{useState, useEffect} from 'react';
import Layout from "../components/layout"
import '../components/suelos/style.scss';
import DesktopMapaConductividad from '../components/suelos/desktop/mapaconductividad';
import DesktopLaConsulta from '../components/suelos/desktop/laconsulta';
import DesktopParajeAltamira from '../components/suelos/desktop/parajeAltamira';
import DesktopEugenioBustos from '../components/suelos/desktop/eugenioBustos';
import DesktopPerfilAndes from "../components/suelos/desktop/perfilAndes"
import DesktopVideoHeader from '../components/suelos/desktop/VideoHeader';
import MobileVideoHeader from '../components/suelos/mobile/VideoHeader'
import MobileMapaConductividad from '../components/suelos/mobile/mapaconductividad';
import MobileParajeAltamira from '../components/suelos/mobile/ParajeAltamira'
import MobileLaConsulta from '../components/suelos/mobile/LaConsulta';
import MobileEugenioBustos from '../components/suelos/mobile/EugenioBustos'
import SimpleLocalize from "../components/SimpleLocalize";

const IndexPage = (props) => {
	const [isMobile, setIsMobile] = useState(false);
	useEffect(() => {
	  if (typeof window !== "undefined") {
		const handleResize = () => {
		  setIsMobile(window.innerWidth <= 768);
		};

		handleResize();

		window.addEventListener("resize", handleResize);

		return () => {
		  window.removeEventListener("resize", handleResize);
		};
	  }
	}, []);

	const isDesktop = !isMobile;

	return (
		<SimpleLocalize {...props}>
			<Layout page='suelos' {...props}>
			{isDesktop ? <>
					<DesktopVideoHeader/>
					<DesktopPerfilAndes {...props} />
					<DesktopMapaConductividad {...props} />
					<DesktopParajeAltamira {...props} />
					<DesktopLaConsulta {...props} />
					<DesktopEugenioBustos {...props} />
					</>
					:
				<>
					<MobileVideoHeader />
					<MobileMapaConductividad {...props} />
					<MobileParajeAltamira {...props} />
					<MobileLaConsulta {...props} />
					<MobileEugenioBustos {...props} />
				</>
			}
			</Layout></SimpleLocalize>
	)
}

export default IndexPage

import React, { useState } from 'react';
import { FormattedMessage } from "react-intl";
import SimpleLocalize from "../../SimpleLocalize";
import Section from '../../common/Section';

const LaConsulta = (props) => {
    const { pageContext } = props;
    let camino = '../';
    if(typeof window !== 'undefined'){
        camino = window.location.origin
    }
    let caminoimg = '..'
	let poslang = '';
    if(pageContext.language == 'en'){
        caminoimg = '../..';
		poslang = "_en";
    }
    const items = [{ img: `${caminoimg}/suelos/perfilsuelo_LC${poslang}.jpg`, label: pageContext.messages["calicata"], style:{transform: 'scale(1.5)', marginTop: '70px'}  },
    { img: `${camino}/tour/calicatas.html?startscene=1&lang=${pageContext.language}`, label: pageContext.messages["vista360"], style:{} },
    { img: `${caminoimg}/suelos/mapa_suelo_zonas_LC${poslang}.jpg`, label: pageContext.messages["ubicacion"], style:{} }]
    const [activeIndex, setActiveIndex] = useState(0);

    const handleOnClick = (index) => {
        setActiveIndex(index);
    }

    return (
        <SimpleLocalize {...props}>
        <Section sectionClasses="py-0 text-center position-relative  tipo-suelo" childClasses=' bg-white no-top'>
            <div className="container content-wrapper">
                <div className="row">
                    <div className="col-12 text-start pt-3">
                        <div>
                            <h1 className="title-2 mt-3">La Consulta</h1>
                            <hr />
                            <p>
                                <FormattedMessage id="suelo-franco-arenoso" defaultMessage="suelo-franco-arenoso" />.<br/>
                                <FormattedMessage id="semi-profundo" defaultMessage="semi-profundo" />.<br/>
                                <FormattedMessage id="presencia-de-rocas-medianas" defaultMessage="presencia-de-rocas-medianas" />.
                            </p>
                        </div>
                        <div className="ficha mt-2 p-4">
                            <h4><FormattedMessage id="origen-y-formacion" defaultMessage="origen-y-formacion" /></h4>
                            <p><FormattedMessage id="fluvial-aluvional" defaultMessage="fluvial-aluvional" /></p>
                            <h4><FormattedMessage id="pendiente" defaultMessage="pendiente" /></h4>
                            <p><FormattedMessage id="1-a-3-por-ciento" defaultMessage="1-a-3-por-ciento" /></p>
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-12'>
                        <nav className="navbar-expand-lg navbar-light">
                            <div className="navbar-nav d-flex flex-row">
                                {items.map((item, index) => {
                                    return (<a className={activeIndex == index ? "nav-item nav-link active" : "nav-item nav-link unactive"} onClick={() => handleOnClick(index)}>{item.label}</a>)
                                })}
                            </div>
                        </nav>
                    </div>
                    <div className="col-12 p-0">
                        {items.map((item, index) => {
                            return (item.label.search('360') === -1 ? <img className={activeIndex == index ? "d-block img-fluid" : "d-none"} src={item.img} style={item.style} /> :
                                <iframe className={activeIndex == index ? "d-block" : "d-none"} style={{ "width": "100%", "height": "400px" }} src={item.img} />)
                        })}
                    </div>
                </div>
            </div>
        </Section>
        </SimpleLocalize>
    );
};

export default LaConsulta;
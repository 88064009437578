import React from 'react';
import Section from '../../common/Section';
import { FormattedMessage } from "react-intl";
import { mobileVideoHeaderStyles } from '../Styles';

const VideoHeader = ({youtubeurl, titleText, descriptionText, backimg, htmlText}) => {
    return (
        <Section sectionClasses="py-0 text-center position-relative video-header-mobile overflow-hidden" childClasses='header-video' childStyle={{ backgroundImage: "url(" + backimg + ")" , ...mobileVideoHeaderStyles }}>
            <div className="video-wrapper w-100 h-100">
                <iframe
                    title="video introduction"
                    frameBorder="0"
                    height="400%"
                    width="400%"
                    src={youtubeurl}
                >
                </iframe>
            </div>
            <div className="min-vh-100 content-wrapper d-flex flex-column justify-content-center container text-white">
                <div style={{ "width": "100%", "height": "100vh", "background": "transparent", "position": "absolute", "z-index": "1", "left": "0" }}></div>
                <h1 style={{ "z-index": "2" }}><FormattedMessage id={titleText} defaultMessage={titleText}/></h1>
                {descriptionText && <p style={{ "z-index": "2" }} className="col-12 text-center">
                    <FormattedMessage id={descriptionText} defaultMessage={descriptionText} />
                </p>}
                {htmlText && <p style={{ "zIndex": "2" }} className="col-12 text-center" dangerouslySetInnerHTML={{ __html: htmlText }} />}
            </div>
        </Section>
    );
};

export default VideoHeader;
import React, { useState } from 'react';
import { FormattedMessage } from "react-intl";
import SimpleLocalize from "../../SimpleLocalize";
import Section from '../../common/Section';

const ParajeAltamira = (props) => {
    const { pageContext } = props;
   let camino = '../';
    if(typeof window !== 'undefined'){
        camino = window.location.origin
    }
    let caminoimg = '..'
	let poslang = '';
    if(pageContext.language == 'en'){
        caminoimg = '../..';
		poslang = "_en";
    }

    const items = [{ img: `${caminoimg}/suelos/perfilsuelo_PA${poslang}.jpg`, label: pageContext.messages["calicata"], style:{transform: 'scale(1.5)', marginTop: '70px'} },
    { img: `${camino}/tour/calicatas.html?startscene=2&lang=${pageContext.language}`, label: pageContext.messages["vista360"], style:{} },
    { img: `${caminoimg}/suelos/mapa_suelo_zonas_PA${poslang}.jpg`, label: pageContext.messages["ubicacion"], style:{} }]
    const [activeIndex, setActiveIndex] = useState(0);

    const handleOnClick = (index) => {
        setActiveIndex(index);
    }

    return (
        <SimpleLocalize {...props}>
            <Section sectionClasses="py-0 text-center position-relative tipo-suelo" childClasses='bg-white no-top'>
                    <div className="container content-wrapper">
                        <div className="row">
                            <div className="col-12 text-start pt-3">
                                <div>
                                    <h1 className="title-2 mt-3"><FormattedMessage id="paraje-altamira" defaultMessage="paraje-altamira" /></h1>
                                    <hr style={{"width":"75%"}}/>
                                    <p>
                                        <FormattedMessage id="suelo-franco-arcillo-arenoso" defaultMessage="suelo-franco-arcillo-arenoso" /><br />
                                        <FormattedMessage id="presencia-de-grava-calcarea" defaultMessage="presencia-de-grava-calcarea" /><br />
                                        <FormattedMessage id="rocas-completamente-recubiertas" defaultMessage="rocas-completamente-recubiertas" />
                                    </p>
                                </div>
                                <div className="ficha mt-2 p-4">
                                    <h4><FormattedMessage id="origen-y-formacion" defaultMessage="origen-y-formacion" /></h4>
                                    <p><FormattedMessage id="fluvial-aluvional" defaultMessage="fluvial-aluvional" /></p>
                                    <h4><FormattedMessage id="pendiente" defaultMessage="pendiente" /></h4>
                                    <p><FormattedMessage id="1-a-3-por-ciento" defaultMessage="1-a-3-por-ciento" /></p>
                                </div>
                            </div>
                        </div>
                        <div className='row pt-4'>
                            <div className='col-12'>
                                <nav className="navbar-expand-lg navbar-light">
                                    <div className="navbar-nav d-flex flex-row">
                                        {items.map((item, index) => {
                                            return (<a className={activeIndex == index ? "nav-item nav-link active" : "nav-item nav-link unactive"} onClick={() => handleOnClick(index)}>{item.label}</a>)
                                        })}
                                    </div>
                                </nav>
                            </div>
                        </div>
                        <div className='row'>
                            <div className="col-12 p-0">
                                {items.map((item, index) => {
                                    return (item.label.search('360') === -1 ?
                                        <img className={activeIndex === index ? "d-block img-fluid" : "d-none"} src={item.img} style={item.style} /> :
                                        <iframe className={activeIndex === index ? "d-block" : "d-none"} style={{ "width": "100%", "height": "400px" }} src={item.img} />)
                                })}
                            </div>
                        </div>
                    </div>
            </Section>
        </SimpleLocalize>
    );
};

export default ParajeAltamira;
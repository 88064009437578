import React from 'react';
import Section from '../../common/Section';
import img01 from "../../../images/suelo-01.jpg"
import { FormattedMessage } from "react-intl";

const VideoHeader = () => {
    return (
        <Section sectionClasses="py-0 text-center position-relative min-vh-100" childClasses='bg-holder header-video' childStyle={{ backgroundImage: "url(" + img01 + ")" }}>
            <div className="video-wrapper position-absolute w-100 h-100">
                <iframe
                    title="video introduction"
                    frameBorder="0"
                    height="100%"
                    width="100%"
                    src="https://youtube.com/embed/TWDWWWhpMdo?autoplay=1&controls=0&showinfo=0&autohide=1&rel=0&loop=1&mute=1&playlist=TWDWWWhpMdo&modestbranding=1"
                >
                </iframe>
            </div>
            <div className="min-vh-100 content-wrapper d-flex flex-column justify-content-center container text-white">
                <div style={{ "width": "100%", "height": "100vh", "background": "transparent", "position": "absolute", "z-index": "1", "left": "0" }}></div>
                <h1 style={{ "z-index": "2" }}><FormattedMessage id="suelos" defaultMessage="suelos" /></h1>
                <p style={{ "z-index": "2" }} className="col-8 offset-2 text-center">
                    <FormattedMessage id="tres-suelos-excepcionales" defaultMessage="tres-suelos-excepcionales" />
                </p>
            </div>
        </Section>
    );
};

export default VideoHeader;
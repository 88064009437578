import React, { useState } from 'react';
import { FormattedMessage } from "react-intl";
import SimpleLocalize from "../../SimpleLocalize";

const MapaConductividad = (props) => {
    const { pageContext } = props;
    let camino = '..'
	let poslang = '';
    if(pageContext.language == 'en'){
        camino = '../..';
		poslang = "_en";
    }

    const items = [ { img: `${camino}/suelos/mapa_suelos_calicatas${poslang}.jpg`, label: pageContext.messages["calicatas"] },
                    { img: `${camino}/suelos/mapa_suelos_75cm${poslang}.jpg`, label: pageContext.messages["75cm"] },
                    { img: `${camino}/suelos/mapa_suelos_150cm${poslang}.jpg`, label: pageContext.messages["150cm"] }]
    const [activeIndex, setActiveIndex] = useState(0);

    const handleOnClick = (index) => {
        setActiveIndex(index);
    }
    return (
        <SimpleLocalize {...props}>
        <section className="py-0 text-center position-relative min-vh-100">
            <div className="bg-holder bg-white no-top">
                <div className="min-vh-100 container">
                    <div className="row">
                        <div className="col-md-4 text-start pt-4 texto-con-scroll">
                            <h1 className="title-2 mt-3 pt-2">
                                <span><FormattedMessage id="mapeo-de" defaultMessage="mapeo-de" /></span>
                                <em><FormattedMessage id="conductividad" defaultMessage="conductividad" /></em>
                            </h1>
                            <hr />
                            <nav className="navbar-expand-lg navbar-light">
                                <div id="navbarNavAltMarkup">
                                    <div className="navbar-nav">
                                        {items.map((item, index) => {
                                            return (<a className={activeIndex == index ? "nav-item nav-link active" : "nav-item nav-link unactive"} onClick={() => handleOnClick(index)}>{item.label}</a>)
                                        })}
                                    </div>
                                </div>
                            </nav>
                            <p className="mt-4">
                                <FormattedMessage id="sometimos-nuestro-suelo-a-exhaustivos" defaultMessage="sometimos-nuestro-suelo-a-exhaustivos" />
                            </p>
                            <p>
                                <FormattedMessage id="estos-analisis-tanto" defaultMessage="estos-analisis-tanto" />
                            </p>
                        </div>
                        <div className="col-md-8 img_height">
                            {items.map((item, index) => {
                                return (<img className={activeIndex == index ? "d-block" : "d-none"} src={item.img} />)
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </section>
        </SimpleLocalize>
    );
};

export default MapaConductividad
import React from 'react';
import img01 from "../../../images/suelo-01.jpg"
import VideoHeader from '../../common/mobile/VideoHeader';
const VideoHeaderSuelos = () => {
    return (
        <VideoHeader
            youtubeurl='https://youtube.com/embed/TWDWWWhpMdo?autoplay=1&controls=0&showinfo=0&autohide=1&rel=0&loop=1&mute=1&playlist=TWDWWWhpMdo&modestbranding=1'
            titleText='suelos'
            descriptionText='tres-suelos-excepcionales'
            backimg={img01}
        />
    );
};

export default VideoHeaderSuelos;